.hotpink{
  fill: hotpink;
  stroke: black;
}
.green{
  stroke: black;
  fill: limegreen;
}

.markerdot{
  fill: limegreen;
}
.markerline{
  stroke: red;
}
.catPath{
  fill: none;
  stroke: #000;
  stroke-linecap: round;
  stroke-miterlimit: 10;
}
.catPath2{
  fill: #fff;
}